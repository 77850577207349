import React, { Fragment, useState, useEffect } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { saasTheme } from 'common/src/theme/saas';
import { ResetCSS } from 'common/src/assets/css/style';
import { GlobalStyle, ContentWrapper } from '../containers/Saas/saas.style';
import Navbar from '../containers/Saas/Navbar';
import Footer from '../containers/Saas/Footer';
import CallToActionBottom from '../containers/Saas/TrialSection';
import Layout from '../components/layout';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import SEO from '../components/seo';
import DemoShow from '../containers/Saas/DemoShow';
import ModalForm from '../containers/Saas/modalForm';
import DemoShowSmall from '../containers/Saas/demoShowSmall';
function useWindowSize() {
  const isClient = typeof window === 'object';

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

export default () => {
  if (typeof window === `undefined`) {
    return <></>;
  }
  return (
    <ThemeProvider theme={saasTheme}>
      <Fragment>
        <SEO title="SalesOcean | Contact" />
        <ResetCSS />
        <GlobalStyle />
        <Layout>
          <ContentWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar />
              </DrawerProvider>
            </Sticky>
            {useWindowSize().width > 1200 ? <DemoShow /> : <DemoShowSmall />}
            <CallToActionBottom />
            <Footer />
          </ContentWrapper>
          <ModalForm />
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
