import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import {
  PortfolioShowcaseWrapper,
  PortfolioShowcaseItem,
  PortfolioLink,
  BuiltWith,
  PortfolioMeta,
  MetaItem,
} from './demoShow.style';
import { PrevButton, NextButton } from '../saas.style';
import ReactPlayer from 'react-player';

const PortfolioShowcase = ({
  sectionWrapper,
  portfolioImage,
  portfolioDetails,
  titleStyle,
  detailsStyle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      saasJson {
        DEMO_VIDEOS {
          title
          portfolioItem {
            title
            description
            view
            video
            love
            feedback
            buildWith {
              content
            }
          }
        }
      }
    }
  `);

  const [key, setKey] = useState('1');

  useEffect(() => {
    if (window) {
      var hash = window.location.hash;
      if (hash) {
        hash = hash.slice(1, hash.length);
        setKey(hash);
      }
    }
  }, []);

  return (
    <Box {...sectionWrapper} as="section" id="portfolio_section">
      <Container noGutter mobileGutter width="1200px">
        {/* <Box {...secTitleWrapper}>
          <Heading {...secTitle} content="Making Sales easier!" />
          <Text
            {...secDescription}
            content="Year after year, Blue Label Labs has been recognized as one of the top design and development firms in New York City. It’s nice to feel appreciated!"
          />
        </Box> */}

        <PortfolioShowcaseWrapper>
          <Tabs
            renderTabBar={() => <ScrollableInkTabBar />}
            renderTabContent={() => <TabContent animated={false} />}
            tabBarPosition="left"
            activeKey={key || '1'}
            defaultActiveKey="1"
            onTabClick={newKey => {
              setKey(newKey);
            }}
            onChange={newKey => {
              setKey(newKey);
            }}
          >
            {Data.saasJson.DEMO_VIDEOS.map((tabItem, index) => (
              <TabPane
                tab={
                  <Text
                    content={tabItem.title}
                    data-text={tabItem.title}
                    as="span"
                  />
                }
                key={index + 1}
              >
                <PortfolioShowcaseItem>
                  <Box {...portfolioImage}>
                    <Heading
                      content={tabItem.portfolioItem.title}
                      {...titleStyle}
                    />
                    <ReactPlayer
                      controls={true}
                      url={tabItem.portfolioItem.video}
                    />
                    {tabItem.portfolioItem.view ||
                    tabItem.portfolioItem.love ||
                    tabItem.portfolioItem.feedback ? (
                      <PortfolioMeta>
                        {tabItem.portfolioItem.view ? (
                          <MetaItem>
                            <b>{tabItem.portfolioItem.view}</b> Views
                          </MetaItem>
                        ) : (
                          ''
                        )}
                        {tabItem.portfolioItem.love ? (
                          <MetaItem>
                            <b>{tabItem.portfolioItem.love}</b> Likes
                          </MetaItem>
                        ) : (
                          ''
                        )}
                        {tabItem.portfolioItem.feedback ? (
                          <MetaItem>
                            <b>{tabItem.portfolioItem.feedback}</b> Feedback
                          </MetaItem>
                        ) : (
                          ''
                        )}
                      </PortfolioMeta>
                    ) : (
                      ''
                    )}
                  </Box>
                  <Box {...portfolioDetails}>
                    <PortfolioLink>
                      <a href="https://salesocean.io/demo">GET A LIVE DEMO</a>
                    </PortfolioLink>

                    <Text
                      content={tabItem.portfolioItem.description}
                      {...detailsStyle}
                    />
                    {tabItem.portfolioItem.buildWith ? (
                      <BuiltWith>
                        {tabItem.portfolioItem.buildWith.map((item, index) => (
                          <span key={`buildWith-item-${index}`}>
                            {item.content}
                          </span>
                        ))}
                      </BuiltWith>
                    ) : (
                      ''
                    )}
                  </Box>
                </PortfolioShowcaseItem>
              </TabPane>
            ))}
          </Tabs>
        </PortfolioShowcaseWrapper>
      </Container>
    </Box>
  );
};

PortfolioShowcase.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  portfolioImage: PropTypes.object,
  portfolioDetails: PropTypes.object,
  titleStyle: PropTypes.object,
  detailsStyle: PropTypes.object,
};

PortfolioShowcase.defaultProps = {
  sectionWrapper: {
    pt: ['60px', '80px', '100px', '110px', '150px'],
    pb: ['60px', '80px', '100px', '110px', '150px'],
  },
  secTitleWrapper: {
    width: ['100%', '100%', '60%', '50%', '50%'],
    mb: ['50px', '65px'],
  },
  secTitle: {
    fontSize: ['22px', '26px', '26px', '30px', '30px'],
    fontWeight: '600',
    color: '#302b4e',
    lineHeight: '1.34',
    mb: ['15px', '18px', '18px', '20px', '20px'],
  },
  secDescription: {
    fontSize: ['15px', '16px'],
    fontWeight: '400',
    color: '#43414e',
    lineHeight: '1.5',
    mb: '0',
  },
  portfolioImage: {
    width: [1, 1, 1],
    p: ['30px 0 0 0', '40px 0 0 0', '0 0 0 30px', '0 50px', '0 50px'],
  },
  portfolioDetails: {
    width: [1, 1, 1],
    p: ['30px 0 0 0', '40px 0 0 0', '0 0 0 30px', '0 50px', '0 50px'],
  },
  titleStyle: {
    fontSize: ['22px', '22px', '26px', '40px', '40px'],
    fontWeight: '600',
    color: '#302b4e',
    mb: '17px',
  },
  detailsStyle: {
    fontSize: ['15px', '15px', '15px', '16px', '16px'],
    color: '#43414e',
    lineHeight: '1.5',
    mb: '0',
  },
};

export default PortfolioShowcase;
