import React, { useState, useEffect } from 'react';
import Modal from '../modal';
import ContactForm from '../contactForm';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import axios from 'axios';
import Button from 'common/src/components/Button';
import PropTypes from 'prop-types';
import { RootWrapper } from './modalForm.style';
import { Cookies } from 'react-cookie-consent';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function validateWebsite(website) {
  const re = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
  return re.test(String(website).toLowerCase());
}

const requiredFields = [
  { key: 'url', label: 'Website' },
  { key: 'name', label: 'Name' },
  { key: 'email', label: 'Email address' },
  { key: 'reps', label: 'Sales Team size' },
];

const initialState = {
  email: null,
  name: null,
  url: null,
  reps: null,
};

const ModalForm = ({ btnStyle }) => {
  const [open, setOpen] = useState(true);
  const [acceptedConditions, setAcceptedConditions] = useState(false);
  const [form, setForm] = useState(initialState);

  useEffect(() => {
    var cookieSet = Cookies.get('view_demo_videos_cfsnjs');
    if (cookieSet) {
      setOpen(false);
    }
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setNotification({
      open: false,
      text: '',
      sort: '',
    });
  };

  function handleSubmit() {
    setBackdrop(true);
    if (!acceptedConditions) {
      setNotification({
        open: true,
        sort: 'error',
        text: `Please accept terms and conditions`,
      });
      setBackdrop(false);
      return;
    }
    const { validation, validationMessage } = handleValidation();
    if (!validation) {
      setNotification({
        open: true,
        sort: 'error',
        text: `Please enter ${validationMessage}`,
      });
      setBackdrop(false);

      return;
    } else {
      Cookies.set('view_demo_videos_cfsnjs', true);
      setOpen(false);
      axios
        .post('https://api-dot-salesoceanio.ew.r.appspot.com/api/submitForm', {
          ...form,
          sendVerificationEmail: false,
          topic: 'submission for demo video access',
        })
        .then(res => {})
        .catch(err => {
          console.error(err);
        });
      return;
    }
  }

  const [notification, setNotification] = useState({
    open: false,
    text: null,
    sort: null,
  });
  const [backdrop, setBackdrop] = useState(false);

  function handleValidation() {
    var validation = true;
    var validationMessage = '';
    requiredFields.forEach(vali => {
      if (!form[vali.key]) {
        validation = false;
        validationMessage += validationMessage ? `, ${vali.label}` : vali.label;
      }
    });
    if (!validateEmail(form.email)) {
      validation = false;
      validationMessage += validationMessage
        ? ', a valid email address'
        : 'a valid email address';
    }
    if (form.website && !validateWebsite(form.website)) {
      validation = false;
      validationMessage += validationMessage
        ? ', a valid website url'
        : 'a valid website url';
    }

    return { validation, validationMessage };
  }

  return (
    <Modal open={open}>
      <RootWrapper>
        <ContactForm
          title=""
          button={
            <>
              <Button title="GET ACCESS" {...btnStyle} onClick={handleSubmit} />
            </>
          }
          form={form}
          setForm={setForm}
          acceptedConditions={acceptedConditions}
          setAcceptedConditions={setAcceptedConditions}
        />
      </RootWrapper>

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={notification.sort}>
          {notification.text}
        </Alert>
      </Snackbar>
      <Backdrop
        style={{ zIndex: 999, color: '#fff' }}
        open={backdrop}
        onClick={() => setBackdrop(!backdrop)}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </Modal>
  );
};

ModalForm.propTypes = {
  btnStyle: PropTypes.object,
};

ModalForm.defaultProps = {
  btnStyle: {
    minWidth: '130px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#fff',
    borderRadius: '4px',
    mr: ['5px', '15px'],
    mt: '5px',
    colors: 'primaryWithBg',
  },
};

export default ModalForm;
